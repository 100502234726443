import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { usePageSetup } from "@components/usePageSetup"
import { Layout } from "@components/Layout"
import { Seo } from "@components/Seo"
import { Hero } from "@components/Hero"
import { Carousel } from "@components/Carousel"
import { CursorMarker } from "@components/CursorMarker"
import { Arrow } from "@components/UI"
import { AboutJk } from "@components/AboutJk"
import { SalesForm } from "@components/Form"
import { Lottie } from "@components/Lottie"
import { splitText } from "@utils"
import megaphoneLottie from "@lottie/megaphone_b.json"
import lightbulbLottie from "@lottie/lightbulb_b.json"
import blockheartsLottie from "@lottie/blockhearts_b.json"
import flowLottie from "@lottie/flow_b.json"

const BrandingPage = ({ data }) => {
  const { heroImageDesktop, heroImageTablet, heroImageMobile } = data
  const { themeColor } = usePageSetup({ themeColor: "bg-blue-light", displayGeneralForm: false })

  return (
    <Layout>
      <Seo title="Branding" description="" />
      <section className={`${themeColor} overflow-hidden`}>
        <Hero className="pb-clamp-36-54 pt-clamp-16-26">
          <Hero.Title>
            <div className="grid-cols-10 lg:grid">
              <div className="col-span-7">
                <h1
                  data-aos="stagger"
                  dangerouslySetInnerHTML={{
                    __html: splitText({
                      "text-white": "Successful brands",
                      "text-gray-darkest": "don’t stand still.",
                    }),
                  }}
                />
              </div>
            </div>
          </Hero.Title>
          <Hero.Message overide={true}>
            <div className="w-full grid-cols-10 lg:grid gap-x-4 mt-11">
              <div className="col-span-7 col-start-2">
                <div data-aos="fade-up">
                  <p>
                    To connect with the people who matter, you need to stay relevant. We have a proven way to make sure
                    you do.
                  </p>
                </div>
              </div>
            </div>
          </Hero.Message>
        </Hero>
      </section>
      <Arrow colorTop="bg-gray-darkest" colorBottom="bg-white" fillColor="text-white" />
      <main>
        <section className="overflow-hidden">
          <div className="container relative">
            <div className="absolute inset-x-0 hidden xl:block">
              <div className="lg:aspect-w-1436 lg:aspect-h-570 extend bg-blue-light before:bg-blue-light after:bg-blue-light"></div>
            </div>
          </div>
          <div className="grid w-full grid-rows-12 xl:grid-rows-10">
            <div className="col-start-1 col-end-2 row-start-1 row-end-8 bg-blue-light extend before:bg-blue-light xl:hidden"></div>
            <div className="container flex col-start-1 col-end-2 row-start-1 pr-0 row-end-8 xl:w-extended-10/12 xl:ml-auto xl:mr-0">
              <div className="flex w-full ml-auto sm:w-11/12 xl:w-full">
                <div className="w-full aspect-w-351 aspect-h-590 md:aspect-w-605 md:aspect-h-778 lg:aspect-w-1436 lg:aspect-h-920">
                  <div data-aos="slide-up" data-aos-delay="400">
                    <div className="hidden image-fill xl:block">
                      <GatsbyImage
                        objectFit="cover"
                        loading="eager"
                        image={getImage(heroImageDesktop)}
                        alt="sales enablement"
                      />
                    </div>
                    <div className="hidden image-fill md:block xl:hidden">
                      <GatsbyImage
                        objectPosition="top"
                        objectFit="cover"
                        image={getImage(heroImageTablet)}
                        alt="sales enablement"
                      />
                    </div>
                    <div className="image-fill md:hidden">
                      <GatsbyImage
                        objectPosition="top right"
                        objectFit="cover"
                        image={getImage(heroImageMobile)}
                        alt="sales enablement"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="relative col-start-1 col-end-2 row-start-5 row-end-12 xl:row-start-4 xl:row-end-10">
              <div className="container flex h-full">
                <div className="relative flex w-5/6 lg:w-8/12 xl:w-7/12">
                  <div
                    data-aos="fade-up"
                    className="relative w-full mt-auto bg-teal-light extend before:bg-teal-light md:aspect-w-605 md:aspect-h-790 xl:before:bg-transparent xl:aspect-w-830 xl:aspect-h-836"
                  >
                    <div className="flex items-center grid-cols-7 lg:grid">
                      <div className="col-start-2 col-end-7 py-16 pr-12 md:py-0 lg:pr-0">
                        <p>
                          How can your brand capture attention, telegraph value, and boost loyalty—even while your brand
                          landscape is shifting?
                        </p>
                        <p>
                          It requires taking a fresh look at who you are and who you want to be—and understanding what
                          your target audiences value most.
                        </p>
                        <p>
                          It means building a brand story that’s bigger than the products you provide, one that
                          resonates in emotional ways. A voice that rings out clearly through the noise. An identity
                          that stands out from the competition.
                        </p>
                        <p>
                          A brand position that’s relevant to your customers and partners—and your evolving brand
                          landscape.
                        </p>
                        <p>
                          Whether you’re launching a new brand or refreshing a mature one, JK’s Brand Relevance Process
                          delivers an authentic brand that’s aligned with your business goals, supports your strategic
                          initiatives, and can grow with you.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="pb-clamp-49-73">
          <div className="container">
            <div className="mx-auto lg:grid-cols-10 lg:w-10/12">
              <h2
                className="text-title"
                data-aos="stagger"
                dangerouslySetInnerHTML={{
                  __html: splitText({
                    "text-gray-darkest": "Together, we can:",
                  }),
                }}
              />
            </div>
          </div>
          <div className="container mt-clamp-13-25">
            <div className="grid-cols-6 mx-auto sm:grid lg:grid-cols-10 lg:w-10/12">
              <div className="col-span-5 lg:col-start-2 lg:col-span-6">
                <div data-aos="fade-up">
                  <Lottie animation={lightbulbLottie} />
                </div>
                <div data-aos="fade-right">
                  <h3 className="text-clamp-26-32 mt-clamp-8-15">Infuse it with insight.</h3>
                  <p className="mt-clamp-9-12">
                    From your industry to your competitors to your customers, we’ll get the full picture of your current
                    brand landscape—and glean the insights needed to connect with the people who matter to your
                    business.
                  </p>
                </div>
              </div>
            </div>
            <div className="grid-cols-12 mx-auto sm:grid lg:grid-cols-10 lg:w-10/12 mt-clamp-22-25">
              <div className="col-span-9 col-start-4 lg:col-start-5 lg:col-span-7">
                <div data-aos="fade-up">
                  <Lottie animation={flowLottie} />
                </div>
                <div data-aos="fade-left">
                  <h3 className="text-clamp-26-32 mt-clamp-8-15">Produce a potent position.</h3>
                  <p className="mt-clamp-9-12">
                    We’ll collaboratively create the building blocks of your new brand—highlighting what makes you
                    uniquely valuable, making your messaging more meaningful, and developing a compelling story that
                    sets you apart.
                  </p>
                </div>
              </div>
            </div>
            <div className="grid-cols-6 mx-auto sm:grid lg:grid-cols-10 lg:w-10/12 mt-clamp-22-25">
              <div className="col-span-5 lg:col-start-2 lg:col-span-6">
                <div data-aos="fade-up">
                  <Lottie animation={blockheartsLottie} />
                </div>
                <div data-aos="fade-right">
                  <h3 className="text-clamp-26-32 mt-clamp-8-15">Bring your brand powerfully to life.</h3>
                  <p className="mt-clamp-9-12">
                    A distinctive, engaging, ownable visual identity will differentiate your brand and capture your
                    value and promise.
                  </p>
                </div>
              </div>
            </div>
            <div className="grid-cols-12 mx-auto sm:grid lg:grid-cols-10 lg:w-10/12 mt-clamp-22-25">
              <div className="col-span-9 col-start-4 lg:col-start-5 lg:col-span-7">
                <div data-aos="fade-up">
                  <Lottie animation={megaphoneLottie} />
                </div>
                <div data-aos="fade-left">
                  <h3 className="text-clamp-26-32 mt-clamp-8-15">Launch it, live it, leverage it.</h3>
                  <p className="mt-clamp-9-12">
                    We can help you roll out your new brand in a way that generates awareness, promotes engagement, and
                    attracts new customers—and helps your employees become your biggest brand champions.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Arrow colorTop="bg-gray-darkest" colorBottom="bg-white" fillColor="text-white" />
        <section className="overflow-hidden bg-blue-light pt-clamp-49-71 pb-clamp-25-58">
          <div className="container relative flex justify-center">
            <div className="w-full lg:w-10/12">
              <div>
                <h2
                  data-aos="stagger"
                  className="text-title"
                  dangerouslySetInnerHTML={{
                    __html: splitText({
                      "text-white": "Featured project",
                      break: "hidden md:block",
                      "text-gray-darkest": "snapshots.",
                    }),
                  }}
                />
              </div>
              <div className="grid grid-cols-10 mt-clamp-7-11">
                <div className="col-span-9">
                  <Carousel>
                    <CursorMarker>
                      <CursorMarker.Sales className="bg-white after:text-purple" />
                    </CursorMarker>
                    <Carousel.Swiper>
                      <Carousel.Slide>
                        <div data-aos="fade-up">
                          <Carousel.Sales client="lumendi" />
                        </div>
                      </Carousel.Slide>
                      <Carousel.Slide>
                        <div data-aos="fade-up" data-aos-delay="200">
                          <Carousel.Sales client="ans" />
                        </div>
                      </Carousel.Slide>
                      <Carousel.Slide>
                        <div data-aos="fade-up" data-aos-delay="400">
                          <Carousel.Sales client="spectrum" />
                        </div>
                      </Carousel.Slide>
                    </Carousel.Swiper>
                  </Carousel>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Arrow colorTop="bg-transparent" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />
        <section className="pt-clamp-44-62 pb-clamp-42-53">
          <AboutJk>
            <AboutJk.Default />
          </AboutJk>
        </section>
        <Arrow colorTop="bg-gray-darkest" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />
        <section className="bg-teal-light pt-clamp-34-51 pb-clamp-27-40">
          <div className="container flex lg:justify-center">
            <div className="xl:w-10/12">
              <div>
                <h2
                  data-aos="stagger"
                  className="text-title"
                  dangerouslySetInnerHTML={{
                    __html: splitText({
                      "text-white": "Let’s build a better",
                      break: "hidden sm:block",
                      "text-gray-darkest": "brand together.",
                    }),
                  }}
                />
              </div>
              <div className="grid-cols-10 lg:grid mt-clamp-7-11">
                <div className="col-span-8 xl:col-span-7">
                  <p data-aos="fade-up">Please fill out the form below and we’ll be in touch soon.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="container mt-24">
            <div className="mx-auto xl:w-10/12">
              <SalesForm
                id="632"
                subject="JK Design - Branding"
                recipient="katiek@jkdesign.com, markm@jkdesign.com, hillarym@jkdesign.com"
                fieldColor="bg-blue-light"
              />
            </div>
          </div>
        </section>
      </main>
    </Layout>
  )
}

export default BrandingPage

export const BrandingQuery = graphql`
  query brandingQuery {
    heroImageDesktop: file(relativePath: { eq: "branding/hero-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    heroImageTablet: file(relativePath: { eq: "branding/hero-tablet.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    heroImageMobile: file(relativePath: { eq: "branding/hero-mobile.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`
